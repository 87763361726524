/*
 * ChangeStorePage Messages
 *
 * This contains all the text for the ChangeStorePage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ChangeStorePage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Chọn chi nhánh',
  },
  headerLabel: {
    id: `${scope}.headerLabel`,
    defaultMessage: 'Tạo cửa hàng',
  },
  storeImage: {
    id: `${scope}.storeImage`,
    defaultMessage: 'Hình ảnh',
  },
  disableAddingStore: {
    id: `${scope}.disableAddingStore`,
    defaultMessage: 'Đã có cửa hàng bị khóa trong tài khoản này',
  },
  uploadImage: {
    id: `${scope}.uploadImage`,
    defaultMessage: 'Hoặc tải lên tại đây',
  },
  uploadHintTilte: {
    id: `${scope}.uploadHintTilte`,
    defaultMessage: 'Tải lên hình ảnh cửa hàng',
  },
  uploadHintText: {
    id: `${scope}.uploadHintText`,
    defaultMessage: 'Kéo và thả ảnh định dạng: .JPG, .PNG',
  },
  noteForDisableStore: {
    id: `${scope}.noteForDisableStore`,
    defaultMessage: 'Tài khoản của bạn tạm thời bị gián đoạn vì lý do chậm thanh toán. Vui lòng liên hệ đến bộ phận chăm sóc khách hàng để được hỗ trợ và kích hoạt lại. Cám ơn!',
  }
});
