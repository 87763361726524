import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "app.components.PopupConfirmStoreLocation.title",
    defaultMessage: "Cập nhật vị trí cửa hàng",
  },
  description: {
    id: "app.components.PopupConfirmStoreLocation.description",
    defaultMessage: "Kamereo cần xác nhận địa chỉ cửa hàng của bạn trên bản đồ để có thể giao hàng cho bạn chính xác",
  },
  confirm: {
    id: "app.components.PopupConfirmStoreLocation.confirm",
    defaultMessage: "Xác nhận ngay",
  },
});