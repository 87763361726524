import { useCallback, useEffect, useState } from 'react';
import history from 'utils/history';
import qs from 'qs';
import setLanguageInLocalStorage from 'utils/setLanguageAtStart';
import { Language } from 'types/schema';

function usePopstateLanguage(): [Language, (language: Language) => void] {
  const [lang, setLang] = useState<Language>(() => {
    const lang = (window.localStorage.getItem('lang') as Language) || Language.Vi;
    return lang;
  });

  const memoizedSetLang = useCallback((language: Language) => setLang(language), [setLang]);

  useEffect(() => {
    const handleLocationChange = (location) => {
      const newSearch = qs.parse(location.search, { ignoreQueryPrefix: true });
      setLanguageInLocalStorage(newSearch, location.pathname, memoizedSetLang);
    };

    // Listen for changes in the history
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        handleLocationChange(location);
      }
    });

    // Cleanup the listener on unmount
    return () => {
      unlisten();
    };
  }, [memoizedSetLang]);

  return [lang, setLang];
}

export default usePopstateLanguage;