/*
 *
 * ProductDetailsPage reducer
 *
 */
import MainActionTypes from 'containers/MainLayout/constants';
import ActionTypes from './constants';
import { ContainerState, ContainerActions, RelatedProduct } from './types';

const loadingResources = Array(48)
  .fill(0)
  .map<RelatedProduct>((v, i) => ({
    loading: true,
    id: String(i),
    price: 0,
    originalPrice: 0,
    tags: [],
    isFavorite: false,
  }));

const generateMedias = (data) => {
  let medias;
  if (!data?.medias?.length || !data?.medias) {
    medias = [
      {
        type: 'IMAGE',
        url: data.imageUrl,
      },
    ];
  } else {
    const imageItems = data.medias.filter((media) => media.type !== 'VIDEO');
    if (imageItems.length === data.medias.length) {
      medias = imageItems;
    } else {
      const videoItem = data.medias.find((media) => media.type === 'VIDEO');
      medias = [videoItem, ...imageItems];
    }
  }
  return medias;
};

export const defaultInitialState: ContainerState = {
  product: {
    id: '',
    price: 0,
    originalPrice: 0,
    tags: [],
    loading: true,
    validProductUrl: '',
    alternateProductUrl: '',
    localLang: '',
    alternateLang: '',
    supplier: { id: -1 },
    category: {
      name: '',
      nameLocal: '',
      children: [],
    },
  },
  relateProducts: loadingResources,
  noteItemLoading: false,
};

// Check if there is a preloaded state from the server
const preloadedState = (window as any).__PRELOADED_STATE__?.productDetailsPage || {};

export const initialState: ContainerState = {
  ...defaultInitialState,
  product: {
    ...defaultInitialState.product,
    ...preloadedState,
  },
};

function productDetailsPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        product: {
          ...state.product,
          loading: true,
          medias: [],
        },
      };
    case ActionTypes.FETCH_PRODUCT_SUCCESS:
      if (payload?.validProductUrl) {
        window.history.replaceState('', '', `/products/${payload?.validProductUrl}`);
      }
      return {
        ...state,
        product: {
          ...payload,
          medias: generateMedias(payload),
        },
      };
    case ActionTypes.FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        product: {
          ...state.product,
          loading: false,
        },
      };
    case ActionTypes.FETCH_RELATE_PRODUCTS_REQUEST:

      return {
        ...state,
        relateProducts: loadingResources,
      };
    case ActionTypes.FETCH_RELATE_PRODUCTS_SUCCESS:
      return {
        ...state,
        relateProducts: payload,
      };
    case ActionTypes.FETCH_RELATE_PRODUCTS_FAILURE:
      return {
        ...state,
        relateProducts: [],
      };
    case MainActionTypes.FAVORITE_SUCCESS:
      let product = state.product;
      const products = state.relateProducts;

      const productIndex = products.findIndex((p) => p.id === payload.id);

      if (productIndex > -1) {
        products[productIndex] = { ...products[productIndex], isFavorite: payload.favorite };
      }
      if (product.id === payload.id) {
        product = { ...product, isFavorite: payload.favorite };
      }
      return {
        ...state,
        relateProducts: products,
        product: product,
      };

    case MainActionTypes.SET_PRODUCT_NOTE_REQUEST:
      return { ...state, noteItemLoading: true };

    case MainActionTypes.SET_PRODUCT_NOTE_SUCCESS:
      if (state.product.id === payload.id) {
        return { ...state, product: { ...state.product, note: payload.note }, noteItemLoading: false };
      }

      const updateNoteRelatedProducts = state.relateProducts;
      const updateNoteProductIndex = updateNoteRelatedProducts.findIndex((p) => p.id === payload.id);
      if (updateNoteProductIndex > -1) {
        updateNoteRelatedProducts[updateNoteProductIndex] = {
          ...updateNoteRelatedProducts[updateNoteProductIndex],
          note: payload.note,
        };
      }
      return { ...state, relatedProducts: updateNoteRelatedProducts, noteItemLoading: false };
    case MainActionTypes.SET_PRODUCT_NOTE_FAILURE:
      return { ...state, noteItemLoading: false };
    default:
      return state;
  }
}

export default productDetailsPageReducer;
