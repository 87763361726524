import { Flex, Modal } from 'antd';
import React, { useMemo } from 'react';
import translations from 'translations';
import messages from './messages';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import Pinpoint from 'components/PopupConfirmLocation/pin-point.gif';
import styled from 'styles/styled-components';
import Button from 'components/Button/Button';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { selectIsConfirmLocation } from 'containers/MainLayout/selectors';
import { setIsConfirmLocation } from 'containers/MainLayout/actions';
import { Dispatch } from 'redux';
import { ApplicationRootState } from 'types';
import { Buyer, Store } from 'types/schema';
import { connect } from 'react-redux';

const ModalContainer = styled(Modal)`
  &.ant-modal .ant-modal-content {
    padding: 20px 12px;
    max-width: 500px;
  }
`;

const PopupWrapper = styled(Flex)`
  max-width: 498px;
  text-align: center;
  font-family: 'Maven Pro';
  font-style: normal;
  color: #585a60;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const Heading = styled.h3`
  text-align: center;
  color: #242527;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
`;

const Footer = styled(Flex)`
  color: #585a60;
  text-align: center;
  font-family: 'Maven Pro';
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;

  & > * {
    min-height: 44px;
    padding: 12;
  }
`;

const CancelButton = styled(Button)`
  border-radius: 6px;
  border: 1px solid #cacbce;
  color: #585a60;
  &:hover {
    color: #fff;
  }
`;

interface IStateProps {
  isConfirmLocation: boolean;
}

interface IDispatchProps {
  setIsConfirmLocation: (data: any) => void;
}

interface Props extends IStateProps, IDispatchProps, RouteComponentProps {
  buyer: Buyer;
}

const PopupConfirmStoreLocation: React.FC<Props> = ({
  buyer,
  isConfirmLocation,
  setIsConfirmLocation,
  history,
  location,
}) => {
  const storeId = localStorage.getItem('store');
  const currentStoreData: Store = (storeId && buyer.stores.find((store) => store.id === storeId)) || ({} as Store);
  const isHomepage = location.pathname === '/';
  const isOpenPopupCreateStore = useMemo(() => {
    return !!(
      buyer?.id &&
      buyer.stores?.length &&
      currentStoreData &&
      isHomepage &&
      !isConfirmLocation &&
      currentStoreData.isForceUpdate
    );
  }, [buyer, currentStoreData, isConfirmLocation, isHomepage]);
  const handleConfirm = () => {
    setIsConfirmLocation(true);
  };

  return (
    <ModalContainer
      title={null}
      open={isOpenPopupCreateStore}
      footer={null}
      closable={false}
      maskClosable={false}
      destroyOnClose
      centered
    >
      <PopupWrapper justify="center" gap={12} vertical>
        <SVGIcon
          src={Pinpoint}
          imgAttr={{
            alt: 'pin-point',
            style: {
              width: 80,
              height: 80,
              flexShrink: 0,
              transform: 'rotateY(180deg)',
            },
          }}
        />
        <div>
          <Heading>{translations(messages.title)}</Heading>
          <p>{translations(messages.description)}</p>
        </div>
        <Footer gap={12}>
          {/* <CancelButton type="primary" onClick={handleCancel} block>
            <span>{translations(messages.cancel)}</span>
          </CancelButton> */}
          <Button type="bg-primary" onClick={handleConfirm} style={{ fontWeight: 600 }} block>
            <span>{translations(messages.confirm)}</span>
          </Button>
        </Footer>
      </PopupWrapper>
    </ModalContainer>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  isConfirmLocation: selectIsConfirmLocation(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsConfirmLocation: (data: boolean) => dispatch(setIsConfirmLocation(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(PopupConfirmStoreLocation);

export default withRouter(withConnect);
